// Google auth client id for google login
export const googleClientId =
  "497068155206-k4o1ffn3302rc03q26j3eq1h1karkafd.apps.googleusercontent.com";

/* Vetspire  */
export const VETSPIRE_API_URL = process.env.REACT_APP_VETSPIRE_API;
export const VETSPIRE_API_KEY = process.env.REACT_APP_VETSPIRE_API_KEY;

/*Appointment cancel reason*/

export const APPOINTMENT_CANCEL_REASON = [
  { id: "1", title: `I can’t make it at that time` },
  { id: "2", title: `I don’t need that service anymore` },
  { id: "3", title: `That service is too expensive` },
  { id: "4", title: `Rather not say` },
];

/* weight list for pet */

export const PET_WEIGHT = [
  { value: 6, name: "0 - 12.49 lbs" },
  { value: 15, name: "12.5 - 20.49 lbs" },
  { value: 28, name: "20.5 - 31.49 lbs" },
  { value: 36, name: "31.5 - 45.49 lbs" },
  { value: 52, name: "45.5 - 65.49 lbs" },
  { value: 75, name: "65.5 - 85.49 lbs" },
  { value: 99, name: "85.5 - 105.49 lbs" },
  { value: 110, name: "105.5 lbs+" },
];

/* Days */
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday ",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const MOB_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

/* Months*/
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/*** Google Map API key ***/
export const MAP_API_KEY = "AIzaSyD5EB9A3bqXy3e7SKH9HyCKXN8uA8b0ao4";

/** Get Distanct between two postion **/

export const getDistanceFromLatLon = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 0.621371; // convert to miles;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

// For Success Notification Time-out
export const SUCCESS_ALERT_TIMEOUT = 2000;

//Default Phone Number Formate
export const MASK = "(___) ___-____";

export const CONTACT_NUMBER_TYPE = [
  { name: "Main", value: "Main" },
  { name: "Mobile", value: "Mobile" },
  { name: "Home", value: "Home" },
  { name: "Work", value: "Work" },
  { name: "Fax", value: "Fax" },
  { name: "Other", value: "Other" },
];

// For user session timeout (15 min.)
export const SESSION_TIME_OUT = 15;

// Allowed Serice Type Id for cancel and reschedule
export const ALLOWED_SERVICE_TYPE_ID = ["235", "236"];

// This is auth key for authToken Encrypt/Decrypt
export const SECSECRET_KEY_AUTH_TOKEN =
  "eJftOmeA1wKLmLw8YQLhNZ9uTTULiSktwew2323rerer";

export const SERVICES = ["Care", "Style", "Play", "Stay"];
export const APPOINTMENTS_PARAM_TYPE = ["upcoming", "completed"];
export const VETSPLUSMORE_APP_NAME = "heartandpaws";
export const VETSPLUSMORE_STATUS = "subscribed";
export const VETSPLUSMORE_TYPE = "monthly";
export const PET_VET_ALLOWED_LOCATION = ["22715", "294", "152", "16996", "268", "212", "267", "17228", "120", "22903", "266", "614", "23017", "17226" , "17225", "22897", "22863", "22864", "22748", "23039", "256", "22917"];
export const PHARMACY_ALLOWED_LOCATION = ["17013", "17226", "22864", "23039", "22715", "523", "22863", "268", "267", "614", "152", "120", "22748", "256", "17225", "22897", "22917", "294", "16996", "22903", "266", "567", "6058", "272", "23017", "212", "22639", "17228"];
export const PHARMACY_LOCATIONS_DATA = {
  "17013" : "https://AVS.bluerabbitrx.com",
  "17226" : "https://carolinevet.bluerabbitrx.com",
  "22864": "https://allpets.bluerabbitrx.com",
  "23039": "https://acc.bluerabbitrx.com",
  "22715": "https://centereach.bluerabbitrx.com",
  "523": "https://AVC.bluerabbitrx.com",
  "22863": "https://apachetrail.bluerabbitrx.com",
  "268": "https://hpcallowhill.bluerabbitrx.com",
  "267": "https://hpeastmarket.bluerabbitrx.com",
  "614": "https://hpnorthernliberties.bluerabbitrx.com",
  "152": "https://hpglenmills.bluerabbitrx.com",
  "120": "https://hpkingofprussia.bluerabbitrx.com",
  "22748": "https://midatlantic.bluerabbitrx.com",
  "256": "https://parklane.bluerabbitrx.com",
  "17225": "https://amberwoodvet.bluerabbitrx.com",
  "22897": "https://bethlehem.bluerabbitrx.com",
  "22917": "https://cloverfarm.bluerabbitrx.com",
  "294": "https://hannastown.bluerabbitrx.com",
  "16996": "https://hpballston.bluerabbitrx.com",
  "22903": "https://hplakehopatcong.bluerabbitrx.com",
  "266": "https://hpmarlton.bluerabbitrx.com",
  "567": "https://homestead.bluerabbitrx.com",
  "6058": "https://rockledge.bluerabbitrx.com",
  "272": "https://southpeak.bluerabbitrx.com",
  "23017": "https://wyomingvet.bluerabbitrx.com",
  "212":	"https://hpcherryhill.bluerabbitrx.com",
	"22639": "https://harbor.bluerabbitrx.com",	
	"17228": "https://hpfellspoint.bluerabbitrx.com"
}
